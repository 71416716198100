@import "~common/stylesheet/index";

.header {
  display: flex;
  flex-direction: column;
  min-width: 0;

  .row {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 16px;
    background-color: $theme-white-gray;

    @media only screen and (max-width: $screen-m) {
      padding-left: 0px;
    }
    @media only screen and (max-width: $screen-sm) {
      padding: 0px;
    }

    &.toolbar {
      min-height: 20px;
    }

    &.red{
      min-height: 2.7em;
      background-color: $theme-red;
      &.border{
        border-bottom: 1px solid $theme-white;
      }

      &.player_container{
        @media only screen and (max-width: $screen-sm) {
          justify-content: flex-end;
      }
      }

      @media only screen and (max-width: $screen-sm) {
        width: 100%;
    }
    }

    &.top{
      @media only screen and (max-width: $screen-m) {
        min-height: 90px;
        padding-left: 16px;
      }

      @media only screen and (max-width: $screen-sm) {
        padding-right: 16px;
      }
      min-height: 120px;
    }

    .navContainerMobile{
      display: none;
      position: absolute;
      top: 0;
      width: 100%;
      min-height: 100%;
      height: calc(100vh - 90px);
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      color: $theme-dark;
      background-color: $theme-white-gray;
      z-index: 1000;
      font-size: $font-size-2xl;
      color: $theme-red;
      font-weight: bold;

      &.open{
        display: flex;
      }

      a{
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100px;
        width: 100%;
        border-bottom: 2px solid $theme-white;
        text-decoration: none;
        color: inherit;

        &:first-child{
          border-top: 2px solid $theme-white;
        }
      }
    }

    .section {
      min-height: $line-height;
      display: flex;
      align-items: stretch;

      &.navTop{
        @media only screen and (max-width: $screen-sm) {
          width: 100%;
          justify-content: space-between;
        }
      }

      .logo{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
          @media only screen and (max-width: $screen-sm) {
            height: 45px;
          }
        }
      }

      .navContainer{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // gap: 20px;
        margin-left: 50px;
        .nav{
          font-size: $font-size-4xl;
          color: $theme-dark;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 40px;
          height: 100%;
          &:hover{
            background-color: $theme-white;
            color: $theme-red;
          }
          &.active{
            background-color: $theme-white;
            color: $theme-red;
          }

          @media only screen and (max-width: $screen-m) {
            font-size: $font-size-2xl;
          }
        }
        @media only screen and (max-width: $screen-sm) {
          display: none;
        }
      }

      .mobileHeaderMenu{
        display: none;
        color: $theme-red;
        padding: 0px;

        svg{
          width: 35px;
          height: 35px;
        }

        &:hover{
          background-color:   transparent;
        }

        &:active{
          box-shadow: none;
        }

        @media only screen and (max-width: $screen-sm) {
          display: flex;
        }
      }

      .title_bar {
        font-size: $font-size-large;
        font-weight: bold;
        min-width: 0;

        .nav_arrow {
          margin: 0 4px;
        }

        .nav_caret {
          margin-left: 4px;
        }

        .input_title {
          padding: 4px 8px;
          background-color: $theme-light;
        }
      }

      .btn_dropdown {
        position: relative;
        font-weight: bold;

        &:active {
          box-shadow: none;
        }

        .dropdown {
          z-index: 98;
          position: absolute;
          left: 0;
          top: 0;
          display: none;
          flex-direction: column;
          align-items: stretch;
          box-shadow: 0 0 8px $color-shadow;
          background-color: $theme-light;
          margin-top: $line-height;
        }

        &:hover {
          .dropdown {
            display: flex;
          }
        }
      }

      .mobileMenu{
        display: none;
        background-color: $color-progress-gray;
        color: $theme-red;
        @media only screen and (max-width: $screen-m) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.mobileView{
          @media only screen and (min-width: $screen-sm) {
            display: none;
          }
          svg {
            height: 50% !important;
          }
        }

        &.desktopView{
          @media only screen and (max-width: ($screen-sm - 1)) {
            display: none;
          }
        }
  
        svg{
          height: 100%;
        }
      }
    }

    .share{
      display: flex;
      @media only screen and (max-width: $screen-m) {
        display: none;
      }
    }
  }
}
