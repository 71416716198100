@import "~common/stylesheet/index";

.resizable_container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 0;
  min-height: 0;

  &.horizontal {
    flex-direction: row;
  }

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    &.horizontal {
      flex-direction: row;
    }
  }

  .button_container{
    position: absolute;
    top: 0;
    right: 0;
    color: $theme-dark;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: $theme-white-gray;
    padding: 4px 6px;
    z-index: 10;

    &:hover{
      cursor: pointer;
      filter: brightness(.95);
    }

    img {
      height: 32px;
      padding: 10px 0px;
    }

    .title{
      padding: 0px 5px;
    }

  }
}
