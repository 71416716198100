$line-height: 32px;
$font-size-normal: 12px;
$font-size-large: 14px;
$font-size-xl: 18px;
$font-size-2xl: 20px;
$font-size-3xl: 24px;
$font-size-4xl: 28px;

//Breakpoints
$screen-s: 375px;
$screen-sm: 640px;
$screen-m: 834px;
$screen-l: 1024px;
$screen-xl: 1200px;

:export {
  lineHeight: $line-height;
  fontSizeNormal: $font-size-normal;
  fontSizeLarge: $font-size-large;
  fontSizeXl: $font-size-xl;
  fontSize2Xl: $font-size-2xl;
  fontSize3Xl: $font-size-3xl;
  fontSize4Xl: $font-size-4xl;
  screen-s: $screen-s;
  screen-sm: $screen-sm;
  screen-m: $screen-m;
  screen-l: $screen-l;
  screen-xl: $screen-xl;
}