@import "~common/stylesheet/index";

.renderer {
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 0;
  overflow: hidden;

  &:first-child {
    border-top: none;
  }


  .title {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $theme-white-gray;
    color: $theme-dark;
    padding: 4px 6px;
    font-size: $font-size-xl;
    text-transform: uppercase;
  }
}

.icon {
  margin-left: 4px;
  height: 15px;
  width: 15px;
  position: relative;
  top: -1px;
  transform: rotate(90deg);
}

.rendererCollapsed {
  height: calc(2em + 7px) !important;
  min-height: 0 !important;
  flex: none !important;
  padding: 0 !important;

  .icon {
    transform: rotate(0);
  }
}

.zoomButtons {
  z-index: 9;
  position: absolute;
  top: 0;
  right: 0px;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;

  .button {
    background-color: $theme-red;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $theme-red-hover;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.zoomText {
  display: none;
}

.showHideCtrl {
  cursor: pointer;
}

.infoContainer {
  z-index: 9;
}

.rendererCollapsed .infoContainer {
  width: 100%;
  position: absolute;
  height: 100%;
  background: $theme-white;
  opacity: 1;
}

.rendererCollapsed .zoomButtons {
  z-index: 0;
}