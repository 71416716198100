@import "~common/stylesheet/index";

.myNumberInput {
    color: $theme-dark;
    input {
        border: 1px solid $theme-dark;
        width: 50px;
        text-align: center;

        &.error {
            &:not([disabled]) {
                border: red 1px solid;
            }
        }
    }
}

.myDropdownInput {
    color: $theme-dark;
    select {
        border: 1px solid $theme-dark;
        width: fit-content;
        padding: 5px;

        &.error {
            &:not([disabled]) {
                border: red 1px solid;
            }
        }
    }
}

.myMatrixInput {
    color: $theme-dark;
    margin-left: -6px;
    input {
        border: 1px solid $theme-dark;
        width: 50px;
        text-align: right;

        &.error {
            &:not([disabled]) {
                border: red 1px solid;
            }
        }
    }

    input[disabled] {
        background-color: rgba($theme-light, 0.3);
        cursor: not-allowed;
    }

    table {
        border-spacing: 4px;
        margin-left: -5px;

        .rowHeader{
            text-align: right;
        }
    }

    .dimInput {
        display: flex;
        gap: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 6px;
        align-items: flex-end;
    }
}
