@import "~common/stylesheet/index";

.tab_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 0;
  min-height: 0;
  position: relative;
  background-color: $theme-white-gray;

  .tab_bar {
    display: flex;
    align-items: stretch;
    height: $line-height;
    overflow-x: auto;
    white-space: nowrap;
    flex-shrink: 0;

    .row{
      display: flex;
      flex-wrap: nowrap;
      background-color: $theme-white-gray;

      &:hover{
        cursor: pointer;
        filter: brightness(0.95);
      }
      input{
        cursor: pointer;
        pointer-events: none;
      }

      img {
        height: 100%;
        padding: 10px 0px;
        transform: rotate(180deg);
        margin-inline: 5px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 12px 0px 5px;
      margin: 0;
      color: $theme-dark;

      .input_title {
        input {
          &:hover,
          &:focus {
            margin: -4px;
            padding: 4px;
            background-color: transparent;
          }
        }
      }

      &.selected {
        margin: 0 -1px;
        border-bottom: none;
      }

      &.fake {
        pointer-events: none;

        &:first-child {
          flex-shrink: 0;
          width: calc($line-height / 2);
        }

        &:last-child {
          flex: 1;
        }
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $theme-red;
    overflow: hidden;
  }
}
