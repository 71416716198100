@import "~common/stylesheet/index";

.array_2d {
  flex-shrink: 0;
  display: table;
  border-collapse: collapse;
  color: $theme-dark;

  .row {
    display: table-row;
    height: 28px;

    .col {
      display: table-cell;
      text-align: center;
      min-width: 28px;
      background-color: $theme-white-gray;
      border: 1px solid $theme-light;
      padding: 0 4px;

      &.colHeader{
        text-align: right;
        padding-right: 10px;
      }

      .value {
        font-size: 12px;
      }

      &.selected {
        background-color: $color-selected;
        color: $theme-white;
      }

      &.patched {
        background-color: $color-patched;
        color: $theme-white;
      }

      &.index {
        background: none;
        border: none;
        color: $theme-light;
      }
    }
  }
}
