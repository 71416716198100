@import "~common/stylesheet/index";

.introduction {
    $footerHeight: 100px;
    $footerHeightMobile: 214px;
    display: grid;
    background: $theme-white;
    grid-template-columns: minmax(10px, auto);
    grid-template-rows: auto $footerHeight;
    @media only screen and (max-width: calc($screen-m - 1px)) {
        grid-template-rows: auto $footerHeightMobile;
    }
    grid-template-areas:
        "article"
        "footer";

    height: 100%;
    width: 100%;
    overflow-y: auto;

    @mixin pageMargins() {
        max-width: 1620px;
        min-width: 100px;
        margin: 0 auto;
    }

    @mixin pagePadding() {
        padding: 0 137px;
        @media only screen and (max-width: calc($screen-m - 1px)) {
            padding: 0 36px;
        }
    }

    article {
        @include pageMargins();
        @include pagePadding();
        margin-top: 2em;
        grid-area: article;
        color: black;
        flex-grow: 1;
        font-size: 18px;
        h1 {
            font-size: 30px;
        }
        h2, h3 {
            font-size: 18px;
        }
        h3 {
            margin-top: 2em;
            margin-bottom: 0.5em;
        }
        h2 {
            margin-top: 2em;
            margin-bottom: 0.5em;
        }
        br {
            margin: 0.5em 0;
        }
        p {
            margin-top: 0.5em;
            hyphens: auto;
        }
    }
    .heroImages {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 990px;
        align-items: center;
        img {
            margin-top: 0.5em;
            min-width: 50px;
        }
        .hsuLogo {
            width: 130px;
        }
        .dtecLogo {
            width:200px;
        }            
        .financedLogo {
            width:200px;
        }
        .dgkLogo {
            width:210px;
        }
        margin-bottom: 3em;
        @media only screen and (max-width: calc($screen-m - 1px)) {
            flex-direction: column;
            align-items: flex-start;
            .hsuLogo {
                width: 103px;
            }
            .dtecLogo {
                width:150px;
            }            
            .financedLogo {
                width:150px;
            }
            .dgkLogo {
                width:166px;
            }
        }
    }
    
    footer {
        background: $theme-red;
        grid-area: footer;
        div:first-child {
            // centered container
            @include pageMargins();
            height: 100%;
            div:first-child {
                @include pagePadding();
                padding-right: unset;
                // another flex container with max width, to adjust max width for flex space-between
                max-width: 826px;
                margin: 0;
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                font-size: 18px;
                font-weight: bold;
                @media only screen and (max-width: calc($screen-m - 1px)) {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    vertical-align: baseline;
                    gap: 15px;
                }
            }
        }

        .footerLinks{
            a{
                text-decoration: none;
                color: white;
            }
        }
    }
}