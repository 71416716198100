@import "~common/stylesheet/index";


.custom_dropdown {
    position: relative;
    display: inline-block;
    padding: 0px 16px;
    font-size: $font-size-xl;
    font-weight: bold;
    height: 100%;
    align-items: center;
    display: flex;

    &:hover{
        cursor: pointer;
        background-color: $color-hovered-algorithm;
    }

    .selected_option{
        display: flex;
        flex-direction: row;

        .responsiveText{
            @media only screen and (max-width: 440px) {
              display: none;
            }
          }
        

        .icon{
            padding: 0px 5px;
            &:hover{
                background-color: $color-hovered-algorithm;
            }
        }
    }

    .options{
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        justify-content: center;
        right: 0px;
        z-index: 999;
        overflow: visible;
        width: 100%;
        background-color: $theme-red;
        font-size: $font-size-normal;

        .option{
            padding: 5px 0px;
            width: 100%;
            text-align: center;

            &:hover{
                background-color: $color-hovered-algorithm;
            }

            &.selected{
                background-color: $color-selected-algorithm;
                color: black;
            }
        }
    }
}