@import "~common/stylesheet/index";

@mixin anim {
  transition: all 0.2s;
}

.visualization_viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  overflow: hidden;
  overflow-y: scroll;

  .markdown_container{
    color: $theme-dark;
    border-bottom: 2px solid $color-progress-gray;
  }
  .title_container{
    height: fit-content;
    span{
      display: block;
      font-size: $font-size-xl;
      text-transform: uppercase;
      padding: 4px 6px;
      background-color: $theme-white-gray;
      width: fit-content;
    }
  }
  .markdown_content{
    height: 100%;
    padding: 10px 35px;
    h1{
      font-size: 30px;
    }
    *{
      font-size: 18px;
    }
  }
}

.showHideCtrl {
  z-index: 9;
  cursor: pointer;
}

.icon {
  margin-left: 4px;
  height: 15px;
  width: 15px;
  position: relative;
  top: -1px;
  transform: rotate(90deg);
}

.rendererCollapsed {
  height: calc(2em + 7px) !important;
  min-height: 0;
  flex: none;
  padding: 0;
  overflow: hidden;
  .icon {
    transform: rotate(0);
  }
}
