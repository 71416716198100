@import "~common/stylesheet/index";

.graph {
  flex: 1;
  align-self: stretch;

  .node {
    .circle {
      fill: $theme-light;
      stroke: $theme-dark;
      stroke-width: 1;
    }

    .label {
      fill: $theme-white;
      alignment-baseline: central;
      text-anchor: middle;
    }

    .subLabel {
      font-size: 7px;
      font-weight: bolder;
      fill: black;
      alignment-baseline: central;
      text-anchor: middle;
    }

    .weight {
      fill: $theme-dark;
      font-weight: bold;
      alignment-baseline: central;
      text-anchor: left;
    }

    &.selected {
      .circle {
        fill: $color-selected;
        stroke: $color-selected;
      }
    }

    &.visited {
      .circle {
        fill: $color-patched;
        stroke: $color-patched;
      }
    }
  }

  .edge {
    .line {
      stroke: $theme-dark;
      stroke-width: 2;

      &.flow{
        stroke: $theme-dark;
        marker-start: url(#markerArrowFlow);
      }

      &.flowSelected{
        stroke: $color-flow;
        marker-start: url(#markerArrowFlowSelected);
      }

      &.directed:not(.flow) {
        marker-end: url(#markerArrow);
      }

    }
    
    g{      
      .flow{
        color: $theme-dark;
        fill: $theme-dark;
      }
      
      .flowSelected{
        color: $color-flow;
        fill: $color-flow;
      }
    }

    .weight {
      fill: $theme-dark;
      alignment-baseline: baseline;
      text-anchor: middle;
    }

    &.selected {
      .line {
        stroke: $color-selected;

        &.directed {
          marker-end: url(#markerArrowSelected);
        }
      }

      .weight {
        fill: $color-selected;
      }
    }

    &.visited {
      .line {
        stroke: $color-patched;

        &.directed {
          marker-end: url(#markerArrowVisited);
        }
      }

      .weight {
        fill: $color-patched;
      }
    }
  }

  .arrow {
    fill: $theme-dark;

    &.selected {
      fill: $color-selected;
    }

    &.visited {
      fill: $color-patched;
    }

    &.red {
      fill: $color-flow;
    }
  }

  .groupLabel {
    fill: $theme-dark;
    font-size: 20px;
    alignment-baseline: central;
    text-anchor: middle;
  }

  .dashedLine {
    stroke: $theme-dark;
    stroke-width: 2;
    stroke-dasharray: 5, 5;
  }
}
