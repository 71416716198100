@import "~common/stylesheet/index";

.progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $color-progress-gray;
  cursor: pointer;
  pointer-events: auto;

  @media only screen and (max-width: $screen-sm) {
    width: 60px !important;
    color: $theme-white;
    background-color: $theme-red;
  }

  > * {
    pointer-events: none;
  }

  .active {
    position: absolute;
    height: 100%;
    left: 0;
    background-color: $color-progress;

    @media only screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .label {
    position: absolute;
    color: $theme-red;

    @media only screen and (max-width: $screen-sm) {
      color: $theme-white;
    }

    .current {
      font-weight: bold;

      @media only screen and (max-width: $screen-sm) {
        color: $theme-white;
        text-wrap: nowrap;
        padding: 0px 5px;
      }
    }
  }
}
