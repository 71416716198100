@import "~common/stylesheet/index";

.layout_container{
    display: flex;
    flex-direction: column;
    min-height: fit-content;

    > div{
        min-height: 300px;
        height: fit-content;
        display:  flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid $color-progress-gray;
    }
}