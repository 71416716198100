@import "~common/stylesheet/index";


html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: $font-family-normal;
  -webkit-font-smoothing: subpixel-antialiased;
  user-select: none;
  color: $color-font;
  font-size: $font-size-normal;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  outline: none;
  min-width: 0;
  margin: 0;
  padding: 0;
  line-height: 1.15;
}

#cookie-banner {
  p {
    display: inline-block;
  }
  right: 0;
  margin: 0;
  left: 0;
  background-color: #f6f5f5;
  position: fixed;
  bottom: 0px;
  padding: 25px 25px;
  z-index: 999999;
  color: black;
  font-size: 18px;
  text-align: right;
  a {
    color: blue;
  }
}

#cookie-banner button {
  position: relative;
  right: 0;
  background: $theme-red;
  color: white;
  font-size: 20px;
  padding: 15px 15px;
  margin-left: 1em;
  margin-top: 15px;
  &:hover {
    cursor: pointer;
  }
}
