@import "~common/stylesheet/index";

.list_item {
  min-height: $line-height;
  text-decoration: none;
  color: inherit;

  .label {
    flex: 1;
    hyphens: auto;
  }

  &.indent {
    padding-left: 24px;
  }
}
