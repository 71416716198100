@import "~common/stylesheet/index";

.player {
  .progress_bar {
    width: 160px;
  }

  @media only screen and (max-width: 440px) {
    height: 57px;
  }

  .responsiveText{
    @media only screen and (max-width: 440px) {
      display: none;
    }
  }

  .speed {
    display: flex;
    align-items: center;
    padding: 0 12px;
    white-space: nowrap;
    background-color: $theme-red;

    select{
      background-color: $theme-red;
      padding: 0 12px;
      width: 100%;
      height: 100%;  
      padding: 0pc 16px;

      &:focus{
        outline: none;
      }

      &:hover{
        cursor: pointer;
      }
    
    }
  }
}
