@import "~common/stylesheet/index";

.myInputRenderer {
  display: flex;
  span {
    color: $theme-dark;
    font-size: larger;
    font-weight: bold;
  }
  input, button {
    padding: 5px;
    border-radius: 3px;
  }
  button {
    margin-top: 1em;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &:active {
      background-color: rgba(0, 2, 2, 0.5);
    }
  }
  .inputEntry {
    margin-bottom: 1.75em;
    span {
      font-size: larger;
      font-weight: bold;
    }
  }
  .navigation{
    display: flex;
    gap: 10px;
  }
  .invisible{
    visibility: hidden;
  }
  .errorText{
    color: red;
    display: block;
    transition: all 0.1s;
    transition-delay: 0.1s;
  }
  button {
    font-size: $font-size-xl;
    color: $theme-white;
    background-color: $theme-red;
    border-radius: 0;
    font-weight: bold;
    padding: 5px 15px;
    min-width: fit-content;
    text-wrap: nowrap;

    &:hover {
        background-color: $theme-red-hover;
        cursor: pointer;

    }
}
}