@import "~common/stylesheet/index";

.myGantt{
  
  display: flex;

  .machineLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding-right: 20px;
    color: #242424;
    font-size: larger;
    font-weight: bold;
  }

  .myGanttContent{

    display: flex;
    flex-direction: column;

    .myGanttContentValues {
      display: grid;
      height: 50px;
      border: 1px solid rgba(128, 128, 128, 0.5);
      background-color: lightgray;
      width: fit-content;
      
      .gridItem {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        position: relative;
        
        &::after {
          content: attr(data-name) '\A\A' 'Start: ' attr(data-start) '\A' 'Ende: ' attr(data-end) '\A' 'Dauer: ' attr(data-duration);
          text-align: center;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          background-color: #333;
          color: #fff;
          padding: 5px;
          border-radius: 3px;
          white-space: pre;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          pointer-events: none;
          z-index: 10;
        }
        
        &:hover::after {
          opacity: 1;
        }
      }
      
      .highlighted {
        border: 2px blue solid;
        // transform: scaleY(1.3);
        
        // &::after {
        //   transform: translateX(-50%) scaleY(calc(1 / 1.3));
        // }
        
        // > p {
        //   transform: scaleY(calc(1 / 1.3));
        // }
      }
    }
  
    .myGanttContentLabels {
      display: grid;
      margin-left: -12px;
      
      p{
        margin-top: 3px;
        text-align: center;
        color: #242424;
        font-size: larger;
        font-weight: bold;
        color: black;
      }
    }
  }
} 