@import "~common/stylesheet/index";

.category {
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0px;

  .icon {
    margin-left: 4px;
    height: 25px;
    width: 25px;
  }
}

.expandable_list_item {
  border-bottom: 1px solid $theme-white;

  a{
    font-size: 20px;
  }
}
