@import "~common/stylesheet/index";

.log {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  color: $theme-dark;

  .content {
    padding: 24px;
    font-family: $font-family-monospace;
    white-space: pre-wrap;
    line-height: 1.6;
  }
}
