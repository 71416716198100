$theme-dark: #242424;
$theme-red: #A50134;
$theme-red-hover: #750025;
$theme-white: #FFFFFF;
$theme-white-gray: #F6F5F5;
$theme-normal: #393939;
$theme-light: #505050;
$color-progress: #9F912A;
$color-progress-gray: #DCD9D6;
$color-font: #FFFFFF;
$color-shadow: rgba(#000000, .2);
$color-overlay: rgba(#ffffff, .1);
$color-alert: #f3bd58;
$color-selected: #2962ff;
$color-patched: #c51162;
$color-highlight: #29d;
$color-active: #00e676;
$color-flow: red;
$color-selected-algorithm: #F6F5F5;
$color-hovered-algorithm: #C96685;

:export {
  themeDark: $theme-dark;
  theme-red: $theme-red;
  theme-red-hover: $theme-red-hover;
  theme-white: $theme-white;
  theme-white-gray: $theme-white-gray;
  themeNormal: $theme-normal;
  themeLight: $theme-light;
  colorPorgress: $color-progress;
  colorProgressGray: $color-progress-gray;
  colorFont: $color-font;
  colorShadow: $color-shadow;
  colorOverlay: $color-overlay;
  colorAlert: $color-alert;
  colorSelected: $color-selected;
  colorPatched: $color-patched;
  colorHighlight: $color-highlight;
  colorActive: $color-active;
  colorFlow: $color-flow;
  colorSelectedAlgorithm: $color-selected-algorithm;
  colorHoveredAlgorithm: $color-hovered-algorithm;
}
